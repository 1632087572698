var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { alignItems, backgroundColor, borderRadius, color, display, fontSize, fontWeight, maxWidth, minWidth, position, space, textAlign, themeGet, } from 'styled-system';
import { cursor } from '../../theme/system-utilities';
import { Text } from '../Text';
import { CodeWithButton } from './CodeWithButton';
import { CodeWithCopy } from './CodeWithCopy';
import { INLINE_LAYOUT_TEXT_STYLE, INLINE_LAYOUT_VOUCHER_CODE_CONTAINER } from './styles';
import { VoucherBannerVariant } from './types';
var VoucherCodeText = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
VoucherCodeText.defaultProps = __assign(__assign({}, Text.defaultProps), { m: '0', as: 'p', fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_20', null, null, 'lh_24'], textAlign: 'left' });
var VoucherCodeContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  cursor: pointer;\n\n  .tooltip > div {\n    padding: ", "px;\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  cursor: pointer;\n\n  .tooltip > div {\n    padding: ", "px;\n    ", ";\n  }\n"])), space, color, position, textAlign, borderRadius, backgroundColor, maxWidth, cursor, display, alignItems, fontSize, fontWeight, themeGet('space.sp_8'), minWidth);
VoucherCodeContainer.defaultProps = {
    display: 'flex',
    position: 'relative',
    my: 'sp_4',
    px: ['sp_8', null, null, 'sp_12'],
    py: ['6px', null, null, 'sp_8'],
    borderRadius: 'medium',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: [180, 200, 220],
    maxWidth: [null, '383px', null, '436px'],
};
var Code = function (_a) {
    var _b;
    var _c = _a.onClick, onClick = _c === void 0 ? noop : _c, label = _a.label, code = _a.code, bgColor = _a.bgColor, textColor = _a.textColor, displayFlexRow = _a.displayFlexRow, _d = _a.variant, variant = _d === void 0 ? VoucherBannerVariant.Copy : _d, voucherSave = _a.voucherSave, isVertical = _a.isVertical, width = _a.width, props = __rest(_a, ["onClick", "label", "code", "bgColor", "textColor", "displayFlexRow", "variant", "voucherSave", "isVertical", "width"]);
    if (!code) {
        return null;
    }
    var codeText = (React.createElement(VoucherCodeText, __assign({ "data-testid": "voucher-code", color: textColor }, (displayFlexRow && INLINE_LAYOUT_TEXT_STYLE)),
        label && "".concat(label, " "),
        React.createElement("strong", null, code)));
    var container = function (_a) {
        var children = _a.children, containerProps = __rest(_a, ["children"]);
        return (React.createElement(VoucherCodeContainer, __assign({ "data-testid": "voucher-code-container", backgroundColor: bgColor }, containerProps, props, (displayFlexRow && INLINE_LAYOUT_VOUCHER_CODE_CONTAINER)), children));
    };
    var Variant = (_b = {},
        _b[VoucherBannerVariant.Copy] = CodeWithCopy,
        _b[VoucherBannerVariant.Button] = CodeWithButton,
        _b)[variant];
    return (React.createElement(Variant, { onClick: onClick, container: container, code: code, codeText: codeText, textColor: textColor, width: width }));
};
export default Code;
var templateObject_1, templateObject_2;
