var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { flex, height, marginRight, position } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { themeRight, themeTop } from '../../theme/system-utilities';
import CopyText from '../CopyText';
import CopyIcon from '../CopyText/Icon';
import { Flex } from '../Flex';
import { Text } from '../Text';
import Tooltip, { TooltipPosition } from '../Tooltip';
import { useShowTooltip } from '../Tooltip/hooks';
var VoucherCode = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), flex, marginRight);
VoucherCode.defaultProps = {
    mr: 'sp_32',
    flex: '1',
};
var CopyIconContainer = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, height, themeRight, themeTop);
CopyIconContainer.defaultProps = {
    height: '100%',
    themeTop: 0,
    themeRight: 0,
    position: 'absolute',
    alignItems: 'center',
    mr: ['sp_8', null, null, 'sp_12'],
};
export var CodeWithCopy = function (_a) {
    var onClick = _a.onClick, Container = _a.container, codeText = _a.codeText, textColor = _a.textColor, code = _a.code, width = _a.width;
    var _b = useShowTooltip({
        clickHandler: onClick,
    }), isVisible = _b.isVisible, onTooltipTriggerClick = _b.onTooltipTriggerClick;
    var t = useLocalization().t;
    var content = (React.createElement(Container, { onClick: onTooltipTriggerClick },
        React.createElement(VoucherCode, null,
            React.createElement(Tooltip, { className: "tooltip", show: isVisible, eventListenersDisabled: true, position: TooltipPosition.Top, head: codeText, width: width },
                React.createElement(Text, null,
                    t('h24_voucher_code_copied'),
                    React.createElement("br", null),
                    t('h24_place_on_checkout')))),
        React.createElement(CopyIconContainer, { "data-testid": "copy-icon-container" },
            React.createElement(CopyIcon, { color: textColor }))));
    return (React.createElement(CopyText, { text: code, fallback: content }, content));
};
var templateObject_1, templateObject_2;
