var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { border, borderStyle, borderWidth, maxWidth, minWidth, borderColor as styledBorderColor, themeGet, width, } from 'styled-system';
import { Text } from '../Text';
export var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition["Top"] = "top";
    TooltipPosition["Left"] = "left";
    TooltipPosition["Right"] = "right";
    TooltipPosition["Bottom"] = "bottom";
})(TooltipPosition || (TooltipPosition = {}));
var TooltipText = styled(Text)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  ", ";\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  background-color: ", ";\n  border-radius: 2px;\n  position: absolute;\n  z-index: 500;\n  box-sizing: border-box;\n  box-shadow: 0 0 6px 0 rgba(44, 54, 56, 0.25);\n\n  &::after,\n  &::before {\n    border: solid transparent;\n    content: ' ';\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n    ", ";\n  }\n\n  &::after {\n    border-width: 8px;\n    ", ";\n  }\n\n  &::before {\n    border-width: 9px;\n    ", ";\n  }\n"], ["\n  ", ";\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  background-color: ", ";\n  border-radius: 2px;\n  position: absolute;\n  z-index: 500;\n  box-sizing: border-box;\n  box-shadow: 0 0 6px 0 rgba(44, 54, 56, 0.25);\n\n  &::after,\n  &::before {\n    border: solid transparent;\n    content: ' ';\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n    ", ";\n  }\n\n  &::after {\n    border-width: 8px;\n    ", ";\n  }\n\n  &::before {\n    border-width: 9px;\n    ", ";\n  }\n"])), function (_a) {
    var position = _a.position;
    switch (position) {
        case TooltipPosition.Left:
            return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          top: 50%;\n          right: 100%;\n          margin-right: 10px;\n          transform: translate(0, -50%);\n        "], ["\n          top: 50%;\n          right: 100%;\n          margin-right: 10px;\n          transform: translate(0, -50%);\n        "])));
        case TooltipPosition.Right:
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          top: 50%;\n          left: 100%;\n          margin-left: 10px;\n          transform: translate(0, -50%);\n        "], ["\n          top: 50%;\n          left: 100%;\n          margin-left: 10px;\n          transform: translate(0, -50%);\n        "])));
        case TooltipPosition.Bottom:
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          top: 40px;\n          left: 50%;\n          transform: translate(-50%, 0);\n        "], ["\n          top: 40px;\n          left: 50%;\n          transform: translate(-50%, 0);\n        "])));
        case TooltipPosition.Top:
            return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          top: -20px;\n          left: 50%;\n          transform: translate(-50%, -100%);\n        "], ["\n          top: -20px;\n          left: 50%;\n          transform: translate(-50%, -100%);\n        "])));
        default:
            return '';
    }
}, width, minWidth, maxWidth, borderStyle, borderWidth, styledBorderColor, border, themeGet('colors.sys.neutral.background.default'), function (_a) {
    var position = _a.position;
    switch (position) {
        case TooltipPosition.Left:
            return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n            left: 100%;\n            top: 50%;\n          "], ["\n            left: 100%;\n            top: 50%;\n          "])));
        case TooltipPosition.Right:
            return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n            right: 100%;\n            top: 50%;\n          "], ["\n            right: 100%;\n            top: 50%;\n          "])));
        case TooltipPosition.Bottom:
            return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n            bottom: 100%;\n            left: 50%;\n          "], ["\n            bottom: 100%;\n            left: 50%;\n          "])));
        case TooltipPosition.Top:
            return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n            top: 100%;\n            left: 50%;\n          "], ["\n            top: 100%;\n            left: 50%;\n          "])));
        default:
            return '';
    }
}, function (_a) {
    var theme = _a.theme, position = _a.position;
    var borderColor = theme.colors.sys.neutral.background.default;
    switch (position) {
        case TooltipPosition.Left:
            return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n            border-left-color: ", ";\n            margin-top: -8px;\n          "], ["\n            border-left-color: ", ";\n            margin-top: -8px;\n          "])), borderColor);
        case TooltipPosition.Right:
            return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n            border-right-color: ", ";\n            margin-top: -8px;\n          "], ["\n            border-right-color: ", ";\n            margin-top: -8px;\n          "])), borderColor);
        case TooltipPosition.Bottom:
            return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n            border-bottom-color: ", ";\n            margin-left: -8px;\n          "], ["\n            border-bottom-color: ", ";\n            margin-left: -8px;\n          "])), borderColor);
        case TooltipPosition.Top:
            return css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n            border-top-color: ", ";\n            margin-left: -8px;\n          "], ["\n            border-top-color: ", ";\n            margin-left: -8px;\n          "])), borderColor);
        default:
            return '';
    }
}, function (_a) {
    var theme = _a.theme, position = _a.position;
    var borderColor = theme.colors.sys.neutral.background.active;
    switch (position) {
        case TooltipPosition.Left:
            return css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n            border-left-color: ", ";\n            margin-top: -9px;\n          "], ["\n            border-left-color: ", ";\n            margin-top: -9px;\n          "])), borderColor);
        case TooltipPosition.Right:
            return css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n            border-right-color: ", ";\n            margin-top: -9px;\n          "], ["\n            border-right-color: ", ";\n            margin-top: -9px;\n          "])), borderColor);
        case TooltipPosition.Bottom:
            return css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n            border-bottom-color: ", ";\n            margin-left: -9px;\n          "], ["\n            border-bottom-color: ", ";\n            margin-left: -9px;\n          "])), borderColor);
        case TooltipPosition.Top:
            return css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n            border-top-color: ", ";\n            margin-left: -9px;\n          "], ["\n            border-top-color: ", ";\n            margin-left: -9px;\n          "])), borderColor);
        default:
            return '';
    }
});
TooltipText.defaultProps = __assign(__assign({}, Text.defaultProps), { p: 3, lineHeight: 1, borderStyle: 'solid', borderWidth: 'small', borderColor: 'sys.neutral.border.weak', width: [null, 'max-content'], minWidth: ['100%', '0%'], maxWidth: [null, '30vw'] });
var Wrapper = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var Tooltip = /** @class */ (function (_super) {
    __extends(Tooltip, _super);
    function Tooltip(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            show: props.show,
        };
        _this.handleShow = _this.handleShow.bind(_this);
        _this.handleHide = _this.handleHide.bind(_this);
        _this.handleTouch = _this.handleTouch.bind(_this);
        return _this;
    }
    Tooltip.prototype.componentDidUpdate = function (prevProps) {
        var show = this.props.show;
        if (show !== prevProps.show) {
            this.setState({
                show: show,
            });
        }
    };
    Tooltip.prototype.componentDidMount = function () {
        if (!this.props.eventListenersDisabled &&
            this.node &&
            typeof this.node.addEventListener === 'function') {
            this.node.addEventListener('mouseenter', this.handleShow, false);
            this.node.addEventListener('mouseleave', this.handleHide, false);
            this.node.addEventListener('touchstart', this.handleTouch, false);
        }
    };
    Tooltip.prototype.componentWillUnmount = function () {
        if (!this.props.eventListenersDisabled &&
            this.node &&
            typeof this.node.removeEventListener === 'function') {
            this.node.removeEventListener('mouseenter', this.handleShow, false);
            this.node.removeEventListener('mouseleave', this.handleHide, false);
            this.node.removeEventListener('touchstart', this.handleTouch, false);
        }
    };
    Tooltip.prototype.handleShow = function () {
        this.setState({
            show: true,
        });
    };
    Tooltip.prototype.handleHide = function () {
        this.setState({
            show: false,
        });
    };
    Tooltip.prototype.handleTouch = function () {
        this.handleShow();
        this.handleOutsideClick();
    };
    Tooltip.prototype.handleOutsideClick = function () {
        var _this = this;
        var fn = function (event) {
            if (!_this.node.contains(event.target)) {
                _this.handleHide();
                document.removeEventListener('touchstart', fn, false);
            }
        };
        document.addEventListener('touchstart', fn, false);
    };
    Tooltip.prototype.render = function () {
        var _this = this;
        return (React.createElement(Wrapper, { ref: function (node) {
                _this.node = node;
            }, "data-testid": "tooltip", className: this.props.className },
            this.props.head,
            this.state.show && (React.createElement(TooltipText, { role: "tooltip", "data-testid": "tooltip-content", position: this.props.position, width: this.props.width }, this.props.children))));
    };
    Tooltip.defaultProps = {
        show: false,
        eventListenersDisabled: false,
        position: TooltipPosition.Top,
    };
    return Tooltip;
}(React.Component));
export default Tooltip;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
