export var INLINE_LAYOUT_VOUCHER_CODE_CONTAINER = {
    my: 'sp_0',
    px: 'sp_8',
    py: 'sp_8',
    backgroundColor: 'sys.neutral.background.default',
};
export var INLINE_LAYOUT_TEXT_STYLE = {
    fontSize: ['fs_14', 'fs_16'],
    lineHeight: 'lh_20',
    color: 'sys.neutral.text.strong',
    pr: ['sp_0', null, null, 'sp_0'],
    mx: 'sp_0',
};
