var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { size } from 'polished';
import { maxHeight, maxWidth } from 'styled-system';
import Icon from '../Icon';
var COPY_ICON_SIZE = '26px';
var CopyIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  fill: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  fill: ", ";\n"])), size(COPY_ICON_SIZE), maxWidth, maxHeight, function (_a) {
    var iconColor = _a.color;
    return iconColor;
});
CopyIcon.defaultProps = {
    maxWidth: COPY_ICON_SIZE,
    maxHeight: COPY_ICON_SIZE,
    name: 'copy',
};
export default CopyIcon;
var templateObject_1;
