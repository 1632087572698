var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
var TOOLTIP_DELAY = 5000;
export var useShowTooltip = function (_a) {
    var clickHandler = _a.clickHandler, _b = _a.interval, interval = _b === void 0 ? TOOLTIP_DELAY : _b;
    var _c = __read(useState(false), 2), isVisible = _c[0], setSetVisible = _c[1];
    var onTooltipTriggerClick = useCallback(function () {
        setSetVisible(true);
        if (clickHandler) {
            clickHandler();
        }
    }, [isVisible]);
    var hideTooltip = useCallback(function () {
        setSetVisible(false);
    }, []);
    useEffect(function () {
        if (!isVisible) {
            return;
        }
        var visibilityInterval = setTimeout(function () { return setSetVisible(false); }, interval);
        return function () {
            clearTimeout(visibilityInterval);
        };
    }, [isVisible]);
    return { isVisible: isVisible, onTooltipTriggerClick: onTooltipTriggerClick, hideTooltip: hideTooltip };
};
