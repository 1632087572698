var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, borderRadius, fontSize, fontWeight, lineHeight, minWidth, space, themeGet, } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { Button } from '../Button';
import { INLINE_LAYOUT_TEXT_STYLE } from './styles';
var StyledButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    outline-offset: 0;\n    outline-width: 1px;\n    border-color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    outline-offset: 0;\n    outline-width: 1px;\n    border-color: ", ";\n  }\n"])), space, borderRadius, alignItems, fontSize, fontWeight, lineHeight, minWidth, themeGet('colors.sys.secondary.border.focus'));
StyledButton.defaultProps = {
    kind: 'secondary',
    py: 'sp_6',
    ml: ['sp_8', 'sp_16'],
    borderRadius: 'x-large',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: INLINE_LAYOUT_TEXT_STYLE.fontSize,
    lineHeight: INLINE_LAYOUT_TEXT_STYLE.lineHeight,
};
export var CodeWithButton = function (_a) {
    var codeText = _a.codeText, Container = _a.container, onClick = _a.onClick;
    var t = useLocalization().t;
    return (React.createElement(Container, { onClick: onClick },
        codeText,
        React.createElement(StyledButton, null, t('h24_voucher_button'))));
};
var templateObject_1;
