var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { display, maxWidth, maxHeight, justifyContent, alignItems, height, width, overflow, borderRadius, } from 'styled-system';
import { useOpenDisclaimer } from '../../hooks/drawer';
import { cursor } from '../../theme/system-utilities';
import { Button } from '../Button';
import Icon from '../Icon';
import { Text } from '../Text';
var ICON_SIZES = ['20px', null, null, '24px'];
var INLINE_LAYOUT_TEXT_COLOR = 'sys.neutral.text.strong';
var CtaButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, justifyContent, alignItems, width, maxWidth, cursor, borderRadius);
CtaButton.defaultProps = {
    display: 'flex',
    width: ['100%', 'auto'],
    maxWidth: '100%',
    justifyContent: ['center', 'flex-end'],
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 'medium',
};
var CtaText = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  ", ";\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])), overflow);
CtaText.defaultProps = __assign(__assign({}, Text.defaultProps), { as: 'p', m: 0, fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_20', null, null, 'lh_24'], overflow: 'hidden' });
var ArrowRight = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  fill: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  fill: ", ";\n"])), width, height, maxWidth, maxHeight, function (_a) {
    var iconColor = _a.color;
    return iconColor;
});
ArrowRight.defaultProps = {
    width: ICON_SIZES,
    height: ICON_SIZES,
    maxWidth: ICON_SIZES,
    maxHeight: ICON_SIZES,
    name: 'arrowRight',
};
var CtaButtonWithDrawer = function (_a) {
    var ctaLabel = _a.ctaLabel, ctaColor = _a.ctaColor, ctaDisclaimerText = _a.ctaDisclaimerText, className = _a.className, displayFlexRow = _a.displayFlexRow;
    var showDisclaimerDrawer = useOpenDisclaimer({
        html: ctaDisclaimerText,
    });
    var color = displayFlexRow ? INLINE_LAYOUT_TEXT_COLOR : ctaColor;
    return (React.createElement(CtaButton, { kind: "bare", onClick: showDisclaimerDrawer, className: className },
        React.createElement(CtaText, { color: color }, ctaLabel),
        React.createElement(ArrowRight, { color: color })));
};
export default CtaButtonWithDrawer;
var templateObject_1, templateObject_2, templateObject_3;
